






















































import MediaFile from '@/interfaces/MediaFile.interface';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';

@Component
export default class MediaCarouselComponent extends mixins(BaseMixin) {
  @Prop({ required: true })
  public isWaitingForFilesToLoad!: boolean;

  @Prop({ required: true })
  public files!: MediaFile[];

  @Prop({ required: true })
  public filesCount!: number;
  
  @Prop()
  public placeholderFileURL?: string;

  @Prop()
  public placeholderText?: string;

  private isValidFileURL: boolean = true;

  private isWaiting: boolean = true;

  private selectedSlide: number = 0;

  private onImageLoadError() {
    this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.MEDIA_ERROR');
  }

  private onPlaceholderFileLoadError() {
    this.isValidFileURL = false;
  }

  @Watch('placeholderFileURL', { immediate: true })
  private onPlaceholderFileChanged() {
    this.isValidFileURL = true;
  }

  @Watch('files', { immediate: true })
  private onFilesChanged() {
    this.isWaiting = false;
    this.selectedSlide = 0;
  }

  @Watch('isWaitingForFilesToLoad', { immediate: true })
  private onWaitingChanged() {
    this.isWaiting = this.isWaitingForFilesToLoad;
  }
}
